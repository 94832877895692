@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat-Medium.ttf");
  font-weight: 500;
}
