.MuiStepLabel-root {
  align-items: flex-start !important;
  gap: 8px;
  padding: 0 !important;
}
.MuiStepConnector-root {
  margin-bottom: 8px;
  height: 30px;
}
.MuiSvgIcon-root.MuiStepIcon-root {
  width: 32px !important;
  height: 32px !important;
}

.MuiStepIcon-root {
  border: 1px solid #c5c8cc !important;
  color: #f4f8ff !important;
  border-radius: 50%;
}
.MuiStepIcon-root.Mui-active > text {
  fill: #537fc1 !important ;
}
.MuiStepIcon-root.Mui-completed {
  color: #039855 !important;
  border: none !important;
}
.MuiStepIcon-root.Mui-active {
  border: 1px solid #537fc1 !important;
  color: #f4f8ff !important;
  border-radius: 50%;
}
.MuiStepIcon-text {
  fill: #c5c8cc !important;
  font-family: "Montserrat" !important;
}
.MuiStepConnector-root.Mui-active > span {
  border-color: #039855;
  border-width: 1.5px;
}
